<template>
  <div>
    <el-form ref="dataForm" :model="formData" :rules="rules">
      <el-form-item prop="name" label="奖品名称" :label-width="formLabelWidth">
        <div class="w360">
          <el-input
            v-model="formData.name"
            clearable
            placeholder="请输入奖品名称"
            :disabled="used"
          ></el-input>
        </div>
      </el-form-item>

      <el-form-item prop="position" label="奖品位置" :label-width="formLabelWidth">
        <el-select
          v-model="formData.position"
          class="filter-item w150"
          clearable
          placeholder="奖品位置"
          :disabled="used"
        >
          <el-option
            v-for="item in prizePosition"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        &emsp;&emsp;
        <el-popover
          placement="top-start"
          width="400"
          trigger="click"
          content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
        >
          <el-image src="https://oss.kaoyanvip.cn/attach/file1667808853716.png"></el-image>
          <el-button type="text" slot="reference">位置示例</el-button>
        </el-popover>
      </el-form-item>

      <el-form-item
        label="奖品类型"
        :label-width="formLabelWidth"
        class="custom-form-item"
        required
      >
        <el-form-item prop="prize_type">
          <el-select
            v-model="formData.prize_type"
            class="filter-item w150"
            clearable
            placeholder="奖品类型"
            :disabled="used"
          >
            <el-option :key="1" label="优惠券" :value="1" />
            <el-option :key="2" label="课程" :value="2" />
            <el-option :key="3" label="实物" :value="3" />
          </el-select>
        </el-form-item>
        &emsp;&emsp;
        <el-form-item prop="prize_data" v-if="prizeChoose">
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            @click="$refs.tqTableTransfer.handleShow(formData.prize_type)"
            >选择{{ prizeTypeName }}</el-button
          >
          <span class="f-grey f13 ml10"
            >已选择{{ formData.prize_data.length }}个{{ prizeTypeName }}</span
          >
          <TqTableTransfer
            ref="tqTableTransfer"
            v-model="formData.prize_data"
            :limit="1"
            :multiple="false"
            :disabled="used"
            :produc-type="2"
          />
          <!-- <div class="w360">
            <el-input
              v-model="formData.prize_data"
              clearable
              :placeholder="`请输入${prizeTypeName}`"
            ></el-input>
          </div> -->
        </el-form-item>
      </el-form-item>

      <el-form-item prop="probability" label="中奖概率" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input-number
            v-model="formData.probability"
            controls-position="right"
            :min="0"
            :max="100"
            :disabled="used"
          />
          <span class="f-grey f13 ml10">%</span>
        </div>
      </el-form-item>
      <el-form-item prop="total" label="奖品数量" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input-number
            v-model="formData.total"
            controls-position="right"
            :min="0"
            :max="999999"
          />
        </div>
      </el-form-item>
      <el-form-item prop="daily_number" label="每日最多发放量" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input-number
            v-model="formData.daily_number"
            controls-position="right"
            :min="0"
            :max="999999"
            :disabled="used"
          />
        </div>
      </el-form-item>

      <el-form-item prop="earliest_winning_time" label="最早中奖时间" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-date-picker
            v-model="formData.earliest_winning_time"
            type="datetime"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="used"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item prop="image" label="奖品格子图片" :label-width="formLabelWidth">
        <TqImgUpload
          v-model="formData.image"
          tip-size="140px*120px"
          skin="small"
          :disabled="used"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import TqTableTransfer from "@/components/TqTableTransfer";
import TqImgUpload from "@/components/TqImgUpload";

export default {
  components: {
    TqTableTransfer,
    TqImgUpload,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    used: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prizeTypeName() {
      if (this.formData.prize_type === 1) {
        return "优惠券";
      } else if (this.formData.prize_type === 2) {
        return "课程";
      }
      return "";
    },
    prizeChoose() {
      return this.formData.prize_type === 1 || this.formData.prize_type === 2;
    },
  },
  watch: {
    "formData.probability": {
      // 检测中间概率变化,自动计算其余奖项中奖概率
      handler(v) {
        console.log(v);
        this.$emit("handle-probability", v);
      },
    },
    "formData.name": {
      // 检测奖品名称变化,修改tab标签值
      handler(v) {
        console.log(v);
        this.$emit("handle-name", v);
      },
    },
    "formData.prize_type": {
      handler(v) {
        console.log(v);
        this.formData.prize_data = [];
      },
    },
    value: {
      // 检测奖品名称变化,修改tab标签值
      handler(v) {
        console.log("value = ", v);
      },
    },
  },
  data() {
    return {
      formLabelWidth: "120px",
      prizePosition: [
        { value: 1, label: "位置一" },
        { value: 2, label: "位置二" },
        { value: 3, label: "位置三" },
        { value: 4, label: "位置四" },
        { value: 6, label: "位置六" },
        { value: 7, label: "位置七" },
        { value: 8, label: "位置八" },
        { value: 9, label: "位置九" },
      ],
      formData: this.value,
      rules: {
        name: [{ required: true, message: "请输入奖品名称", trigger: "change" }],
        position: [{ required: true, message: "请选择奖品位置", trigger: "change" }],
        prize_type: [{ required: true, message: "请选择奖品类型", trigger: "change" }],
        prize_data: [{ required: true, message: `请选择奖品`, trigger: "change" }],
        probability: [{ required: true, message: "请输入中奖概率", trigger: "change" }],
        total: [{ required: true, message: "请输入奖品数量", trigger: "change" }],
        daily_number: [{ required: true, message: "请输入每日最多发放量", trigger: "change" }],
        earliest_winning_time: [
          { required: true, message: "请选择最早中奖时间", trigger: "change" },
        ],
        image: [{ required: true, message: "请上传奖品格子图片", trigger: "change" }],
      },
    };
  },
  methods: {
    async submitDataVerify(v) {
      console.log("submitDataVerify = ", v);
      // return await this.$refs.dataForm.validate((valid) => {
      //   console.log("valid = ", valid);
      //   return valid;
      // });
      return new Promise((resolve, reject) => {
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            resolve(valid);
          } else {
            reject(valid);
          }
        });
      });
    },
    clearValidate() {
      this.$refs.dataForm.clearValidate();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/form.less");
</style>
